<script>
import utilsMixin from '@shared/mixins/utils';
import APICustomer from '@school/services/API/Customer';

export default {
  mixins: [utilsMixin],
  data: () => ({
    isLoading: false,
    isFetching: false,
    sales: {
      data: [],
    },
  }),
  methods: {
    getSales(page = 1, firstFetch = false) {
      if (firstFetch) {
        this.isFetching = true;
      } else {
        this.isLoading = true;
      }

      APICustomer.getAffiliateSales({ params: { page } })
        .then((data) => (this.sales = data))
        .finally(() => {
          if (firstFetch) {
            this.isFetching = false;
          } else {
            this.isLoading = false;
          }
        });
    },
  },
  created() {
    this.getSales(1, true);
  },
};
</script>

<template>
  <section>
    <b-skeleton v-if="isFetching" height="400" />
    <b-table
      v-else
      class="box"
      :loading="isLoading"
      :data="sales.data"
      :per-page="sales.meta.per_page"
      :total="sales.meta.total"
      backend-pagination
      paginated
      @page-change="getSales($event)"
    >
      <b-table-column
        field="training.name"
        label="Produit"
        v-slot="{ row }"
      >
        {{ row.training.name }}
      </b-table-column>
      <b-table-column
        field="created_at"
        label="Acheté"
        width="200"
        v-slot="{ row }"
      >
        <div>
          <p>
            <b-tooltip>
              <template #content>
                {{ row.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              {{ row.created_at | momentFromUTC | moment('from') }}
            </b-tooltip>
          </p>
          <p
            v-if="row.transaction_type === 'MULTIPLE'"
            class="mt-2 has-text-weight-bold"
          >
            Payé en plusieurs fois
          </p>
        </div>
      </b-table-column>
      <b-table-column
        field="transaction_amount"
        label="Prix"
        numeric
        v-slot="{ row }"
      >
        {{ row.transaction_amount | formatPrice }}
      </b-table-column>
      <b-table-column
        field="affiliation_amount"
        label="Commission"
        numeric
        v-slot="{ row }"
      >
        <strong class="has-text-success">
          {{ row.affiliation_amount | formatPrice }}
        </strong>
      </b-table-column>

      <template #empty>
        Vous n'avez pas encore généré de ventes avec vos liens.
      </template>
    </b-table>
  </section>
</template>
